<template>
    <div class="common-layout">
        <el-container>
            <el-header>
                <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false">
                    <el-menu-item index="0">
                        <a href="/">
                            <img alt="Vue logo" src="./assets/logo.jpg" style="height: 60px" />
                        </a>
                    </el-menu-item>
                    <el-menu-item index="1" @click="goTarget('#/')">
                        树莓派技术分享
                    </el-menu-item>
                    <div class="flex-grow" />
                </el-menu>
            </el-header>
            <el-main>
                <component :is="currentView" />
            </el-main>
            <el-footer>
                <p class="copyright">
                    <el-link type="info" href="https://beian.miit.gov.cn/">&copy; 京ICP备2020034220号-1:</el-link>
                    <el-link type="info" href="/">树莓派技术分享</el-link>
                    <el-link type="info" href="/">.work</el-link>
                </p>
            </el-footer>
        </el-container>
    </div>
</template>
<script>
import Home from './components/Home.vue'

const routes = {
    '/': Home,
}

export default {
    name: 'App',
    components: {
        Home
    },
    data() {
        return {
            currentPath: window.location.hash
        }
    },
    computed: {
        currentView() {
            return routes[this.currentPath.slice(1) || '/'] || NotFound
        }
    },
    mounted() {
        window.addEventListener('hashchange', () => {
            this.currentPath = window.location.hash
        })
    },
    methods: {
        goTarget(href) {
            if (href.startsWith("#/")) {
                window.open(href, "_self");
            } else {
                window.open(href, "_blank");
            }
        },
        onSubmit() {
            console.log('submit!')
        }
    }
}
</script>
<style>
html,
body,
#app {
    height: 99.5%;
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 0px;
}

body {
    margin: 0;
    padding: 0;
    border: 0
}


.common-layout {
    background-image: url('./assets/bg.jpg');
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    margin-bottom: 0px;
    background-color: unset;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    filter: opacity(0.9);
}

.common-layout .el-container {
    height: 100%;
    margin-bottom: 0px;
}

.common-layout .el-container .el-header {
    padding: 0;
}

.common-layout .el-container .el-main {
    padding: 0;
}

.common-layout .el-container .el-main .wrapper .logo {
    height: 100%;
    margin-top: 10%;
}

.common-layout .el-container .el-footer {
    margin-bottom: 0px;
    padding: 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

.flex-grow {
    flex-grow: 1;
}

.inner h1 {
    font-size: 2.25rem;
    line-height: 1.3;
    letter-spacing: 0.5rem;
}

.inner p {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-size: 0.8rem;
    line-height: 2;
}

.el-drawer__container {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
</style>